<template>
  <div>
    <el-card>
      <div class="flex flex-align-center">
          <div class="flex flex-align-center" style="margin-right:10px">
            <div class="font-14 color:#303133" style="font-weight:bold">关键字：</div>
            <el-input style="width:200px" placeholder="物流公司名称、物流编号" v-model="keywords"></el-input>
          </div>
          <el-button type="primary" style="margin-right:10px" @click="query">查询</el-button>
          <buttonPermissions :datas="'wangdiantongpush'" style="margin-right:10px">
            <el-button type="primary" @click="editmsg({})">添加</el-button>
          </buttonPermissions>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist" v-loading="tableloading">
            <el-table-column prop="OutExpressValue" label="ERP-物流公司名称"></el-table-column>
            <el-table-column prop="OutExpressNo" label="ERP-物流公司编号"></el-table-column>
            <el-table-column prop="ErpTypeValue" label="适用ERP"></el-table-column>
            <el-table-column prop="ExpressValue" label="企店-物流公司"></el-table-column>
            <el-table-column prop="AddTime" label="创建时间"></el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <div class="flex flex-align-center">
                  <buttonPermissions :datas="'wangdiantongpush'" style="margin-right:10px">
                    <el-button type="text" @click="editmsg(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas="'wangdiantongdel'" style="margin-right:10px">
                    <el-button type="text" style="color:#f56c6c" @click="del(scope.row)">删除</el-button>
                  </buttonPermissions>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align:right;margin-top:10px" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
    </el-card>

    
    <el-dialog :visible.sync="mappingShow" :title="rowmsg.Id?'修改物流公司映射':'添加物流公司映射'" width="500PX" v-loading="maploading" custom-class="dialog-body-paddingTop-10">
      <div style="">
        <div class="flex flex-align-center" style="margin-bottom:20px">
          <div class="dialog-label text-right">企店-物流公司：</div>
          <el-select v-model="rowmsg.Express" style="width:300px" placeholder="请选择企店内的物流公司" filterable :disabled="rowmsg.Id>0">
            <el-option v-for="(i,index) in  ExpressList" :key="index" :label="i.ExpressName" :value="i.Id"></el-option>
          </el-select>
        </div>
        <div class="flex flex-align-center" style="margin-bottom:20px">
          <div class="dialog-label text-right">ERP-物流名称：</div>
          <el-input v-model="rowmsg.OutExpressValue" style="width:300px" maxlength="50" placeholder="请输入在ERP内配置的物流公司名称"></el-input>
        </div>
        <div class="flex flex-align-center" style="margin-bottom:20px">
          <div class="dialog-label text-right">ERP-物流编号：</div>
          <el-input v-model="rowmsg.OutExpressNo" style="width:300px" maxlength="50" placeholder="请输入在ERP内配置的物流编号"></el-input>
        </div>
        <div class="flex flex-align-center" style="margin-bottom:20px">
          <div class="dialog-label text-right">适用ERP：</div>
          <el-select v-model="rowmsg.ErpType" style="width:300px" placeholder="请选择映射关系适用的ERP">
            <el-option v-for="(i,index) in  erpList" :key="index" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </div>
      </div>
      <div class="flex flex-align-center flex-justify-center" style="margin-top:30px">
        <el-button @click="mappingShow=false">取消</el-button>
        <el-button type="primary" @click="savemapping">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  expressMappinglist,
  expressMappingedit,
  expressMappingdelete,
} from "@/api/goods"
import {
  commonallexpress
} from "@/api/api"
import buttonPermissions from '@/components/buttonPermissions';
export default {
  components:{
    buttonPermissions
  },
  data () {
    return {
      keywords:'',
      tablelist:[],
      tableloading:false,
      pagesize:20,
      currentPage:1,
      total:0,
      rowmsg:{},
      mappingShow:false,
      maploading:false,
      ExpressList:[],
      erpList:[
        {value:10,label:'旺店通'},
        {value:11,label:'聚水潭'},
      ]
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    
    async getExpressList(){
      try{
        let res = await commonallexpress()
        if(res.IsSuccess){
          this.ExpressList = res.Result
        }
      }finally{
        //
      }
    },
    editmsg(row){
      this.rowmsg = {
        Id:row.Id||0,
        Express:row.Express||'',
        OutExpressValue:row.OutExpressValue||'',
        OutExpressNo:row.OutExpressNo||'',
        ErpType:row.ErpType||''
      }
      this.getExpressList()
      this.mappingShow = true
    },
    async savemapping(){
      if(!this.rowmsg.Express){
        this.$message.error('请选择企店-物流公司')
        return 
      }else if(!this.rowmsg.OutExpressValue){
        this.$message.error('请输入ERP-物流名称')
        return 
      }else if(!this.rowmsg.OutExpressNo){
        this.$message.error('请输入ERP-物流编号')
        return 
      }else if(!this.rowmsg.ErpType){
        this.$message.error('请选择映射关系适用的ERP')
        return 
      }
      try{
        this.maploading = true
        let res = await expressMappingedit(this.rowmsg)
        if(res.IsSuccess){
          this.mappingShow = false
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.maploading = false
      }
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    del(row){
      this.$confirm('你正在操作删除物流公司映射关系。删除后，旺店通内若选择该物流公司发货，物流信息将无法同步至商城。是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await expressMappingdelete({Id:row.Id})
        if(res.IsSuccess){
          this.$message.success('删除成功')
          this.gettablelist()
        }
      }).catch(()=>{})
    },
    
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.keywords,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        }
        let res = await expressMappinglist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    
    handleSizeChange(e){
      this.pagesize = e
      this.currentPage = 1
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
  }
}
</script>

<style lang='less' scoped>

.dialog-label{
  font-size: 14px;
  color: #606266;
  line-height: 36px;
  margin-right: 10px;
  flex-shrink: 0;
  &::before{
    content:'*';
    color:#f56c6c;
    margin-right:4px
  }
}
.text-right{
  width: 130px;
  text-align: right;
}
</style>